import Layout from "./Layout"
import SplashCards from "./SplashCards"
import React from "react";
import styled from 'styled-components';

const Footer = styled.div`
  font-size: 15px;
  h5 {
    text-align: center;
    em {
      color: white;
      font-style: italic;
      font-weight: 300 !important;
    }
    strong {
      font-weight: 700 !important;
    }
  }
`;

export default function SmallCardPage({ data, showFooter, noContainer, large, showHeader, darkMode }) {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout showCta={false} showFooter={false} noContainer={noContainer} darkMode={darkMode} page="home" showHeader={showHeader} title={frontmatter.title} subtitle={frontmatter.subtitle}>
      <SplashCards cards={frontmatter.card} large={large}/>
      <Footer className="splash-message" dangerouslySetInnerHTML={{__html: frontmatter.footer}} />
    </Layout>
  )
}
