import React from "react"
import SplashCardPage from "../components/SplashCardPage"
import useSplashData from "../static_queries/useSplashData"
import { graphql } from "gatsby"

export const query = graphql`
  query getSplashDataTwice {
      markdownRemark(frontmatter: {url: {eq: "splash"}}) {
    id
    frontmatter {
      title
      footer
      subtitle
      card {
        title
        subtitle
        image
        url
        carousel
      }
    }
  }
  }
`


export default function SplashPage({data}) {
  return <SplashCardPage noContainer={true} showFooter={false} darkMode={true} showHeader={false} large={true} data={data}/>
}

