import React from "react"
import { Link } from "gatsby"
import Carousel from "./Carousel"
import { globalHistory } from "@reach/router"

const SplashCards = ({ cards, large=false }) => {
  const path = globalHistory.location.pathname

  return <section className={`${large && "large"} features section text-center`}>
    <div className="features-wrap">
      {
        cards.map((card, index) => {
          return <div className="feature ">
            <div className="feature-inner">
              <div className="feature-icon">
                {card.carousel && card.carousel.length ? <Carousel
                    staggerTransition={ index === 0 }
                    images={card.carousel}/> :
                  <div
                    style={{
                      backgroundImage: `url(${card.image})`,
                    }}
                  />
                }
              </div>
              <h3 className="feature-title">{card.title}</h3>
              <p className="text-lg">
                {card.subtitle}
              </p>
              <Link
                className="button button-primary button-block cta"
                to={path + "/" + card.url}
              >
                Learn More
              </Link>
            </div>
          </div>
        })
      }


    </div>
  </section>
}
export default SplashCards
